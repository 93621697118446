
import Spanish from 'flatpickr/dist/l10n/es.js';
import Polish from 'flatpickr/dist/l10n/pl.js';
import French from 'flatpickr/dist/l10n/fr.js';
import Italian from 'flatpickr/dist/l10n/it.js';
import German from 'flatpickr/dist/l10n/de.js';
import Croatian from 'flatpickr/dist/l10n/hr.js';
import english from 'flatpickr/dist/l10n/default.js';
import Portuguese from 'flatpickr/dist/l10n/pt.js';
import { Locale } from '../enum/locale.enum';

export class Settings {

    public static langs = ['es', 'en', 'fr', 'it', 'pl', 'de', 'pt', 'pl'];

    public static langSettings = {
        'de': {
            'locale': Locale.de,
            'flatpickr': German.de,
            'formatFlatpickr': 'd-m-Y',
            'formatMoment': 'DD-MM-YYYY'
        },
        'es': {
            'locale': Locale.es,
            'flatpickr': Spanish.es,
            'formatFlatpickr': 'd-m-Y',
            'formatMoment': 'DD-MM-YYYY'
        },
        'ie': {
            'locale': Locale.en,
            'flatpickr': english,
            'formatFlatpickr': 'm-d-Y',
            'formatMoment': 'MM-DD-YYYY'
        },
        'en': {
            'locale': Locale.en,
            'flatpickr': english,
            'formatFlatpickr': 'm-d-Y',
            'formatMoment': 'MM-DD-YYYY'
        },
        'fr': {
            'locale': Locale.fr,
            'flatpickr': French.fr,
            'formatFlatpickr': 'd-m-Y',
            'formatMoment': 'DD-MM-YYYY'
        },
        'it': {
            'locale': Locale.it,
            'flatpickr': Italian.it,
            'formatFlatpickr': 'd-m-Y',
            'formatMoment': 'DD-MM-YYYY'
        },
        'pl': {
            'locale': Locale.pl,
            'flatpickr': Polish.pl,
            'formatFlatpickr': 'd-m-Y',
            'formatMoment': 'DD-MM-YYYY'
        },
        'hr': {
            'locale': Locale.hr,
            'flatpickr': Croatian.hr,
            'formatFlatpickr': 'd-m-Y',
            'formatMoment': 'DD-MM-YYYY'
        },
        'pt': {
            'locale':  Locale.pt,
            'flatpickr': Portuguese.pt,
            'formatFlatpickr': 'd-m-Y',
            'formatMoment': 'DD-MM-YYYY'
        }
    };
}