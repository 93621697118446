import { Component, Input, OnInit } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
    selector: 'app-appointments-header',
    templateUrl: './appointments-header.component.html',
})
export class AppointmentsHeaderComponent implements OnInit {
    
    @Input() slug: string;
    isOpenMobile: boolean = true;

    // mobile
    isMobile: boolean = false;
    
    urls = {
        'es': 'https://naturhouse.es',
        'fr': 'https://naturhouse.fr',
        'gb': 'https://naturhouse.co.uk',
        'us': 'https://naturhouseusa.com',
        'it': 'https://naturhouse.it'
    };

    constructor(
        private deviceService: DeviceDetectorService,
    ) {
        this.isMobile = this.deviceService.isMobile();

        
    }
    ngOnInit(): void {
        if(this.slug == 'it') {
            const pixel =  "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '371757208614130');fbq('track', 'PageView');";

            this.generateHeadScript(pixel);

            const google = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TSMRM9S');";
            this.generateHeadScript(google);
            

            const noscript = "<iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-TSMRM9S \"height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>";
            this.generateBodyNoScript(noscript);
        }
    }

    private generateHeadScript(value: string)
    {
        const pixelDiv = document.createElement("script");
        pixelDiv.type = 'text/javascript';
        pixelDiv.text = value;

        document.head.appendChild(pixelDiv);
    }

    private generateBodyNoScript(value: string)
    {
        const pixelDiv = document.createElement("noscript");
        pixelDiv.textContent = value;

        document.body.appendChild(pixelDiv);
    }
}