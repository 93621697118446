import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class AppointmentsService {

    constructor(private http: HttpClient) { }

    private getZipCodeSubject = new Subject<any>();
    public getZipCode$ = this.getZipCodeSubject.asObservable();
    
    setZipCode(zipCode: string) {
        this.getZipCodeSubject.next(zipCode);
    }

    listConfigAndAppointments(name, team_id, date) {
        return this.http.get(`${environment.apiUrl}/appointments/user/config/${name}/${team_id}/${date}`)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }

    listConfigByUserName(name:string) {
        return this.http.get(`${environment.apiUrl}/appointments/user/config/${name}`)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    
    saveCustomerAppointment(data, user?) {
        let url = `${environment.apiUrl}/appointments/customer-appointment`;
        if(user) {
            data.user = user;
            url = `${environment.apiUrl}/appointments/customer-appointment/user`
        }
        return this.http.post(url, data)
            .pipe(map((response: any) => {
                return response;
            }));
    }

    

    getBlockedHoursUser(user_id, team_id, date) {
        return this.http.get(`${environment.apiUrl}/appointments/blocked/hours/${user_id}/${team_id}/${date}`)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }

    getBlockedHours(team_id, date) {
        return this.http.get(`${environment.apiUrl}/appointments/blocked/hours/${team_id}/${date}`)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }

    getScheduleConfirmData(hash: string)
    {
        return this.http.get(`${environment.apiUrl}/appointments/customer-appointment/${hash}`)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }

    getAppointmentByUuid(uuid: string) {
        return this.http.get(`${environment.apiUrl}/external/appointments/${uuid}`)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }

    confirmAppointment(hash: string)
    {
        return this.http.patch(`${environment.apiUrl}/external/appointments/${hash}/confirmed` , {})
        .pipe(map((response: any) => {
            return response.data;
        }));
    }

    reScheduleAppointment(hash: string, data) {
        return this.http.patch(`${environment.apiUrl}/external/appointments/${hash}/reschedule` , data)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
}