<div class="relative z-0 overflow-hidden">
    <aside class="aside flex-shrink-0 border-r border-gray-200 bg-white xl:order-first pb-8"  *ngIf="!customer">
        <div class="px-6 pt-6 pb-4">
            <h2 class="text-lg font-medium text-gray-900">{{ 'customer_list.title' | translate }}</h2>
            <app-filter [filters]="filters" (sendFiltersEmitter)="filterChange($event)"></app-filter>
        </div>

        <nav class="flex-1 overflow-y-auto max-h-60 h-screen bg-gray-50" #scroll (scroll)="onScroll($event)">
            <div class="relative">
                <ul role="list" class="relative z-0 divide-y divide-gray-200">
                    <li *ngFor="let customer of customers">
                        <div (click)="selectCustomer(customer)" class="relative px-6 py-5
                            flex items-center space-x-3
                            hover:bg-gray-50
                            focus-within:ring-2
                            focus-within:ring-inset
                            focus-within:ring-pink-500">
                            <div class="flex-shrink-0">
                                <img class="h-10 w-10
                                    rounded-full"
                                    src="https://eu.ui-avatars.com/api/?name={{customer.name}}&background=fff&background=006C5B&color=fff"
                                    alt="">
                            </div>
                            <div class="flex-1 min-w-0">
                                <a class="focus:outline-none">
                                    <!-- Extend touch target to entire panel -->
                                    <span
                                        class="absolute
                                        inset-0"
                                        aria-hidden="true"></span>
                                    <p class="text-sm
                                        font-medium
                                        text-gray-900">
                                        {{customer.name}}    
                                    </p>
                                    <p class="text-sm
                                        text-gray-500
                                        truncate">
                                        {{customer.email}}
                                    </p>
                                    <p class="text-sm
                                        text-gray-500
                                        truncate">
                                        {{customer.phone}}
                                    </p>
                                </a>    
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </aside>
</div>

<div class="w-full p-2" *ngIf="customer">
    <table class="min-w-full divide-y divide-gray-200 overflow-x-auto">
        <tbody class="bg-white divide-y divide-gray-200">
            <tr class="cursor-pointer">
                <td class="px-6 py-4 whitespace-no-wrap">
                    <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                            <img class="h-10 w-10 rounded-full"
                            src="https://eu.ui-avatars.com/api/?name={{customer.name}}&background=fff&background=006C5B&color=fff">
                        </div>
                        <div class="ml-4">
                            <div class="text-sm leading-5 font-medium text-gray-900">
                                {{customer.name}}
                            </div>
                            <div class="text-sm leading-5 text-gray-500">
                                {{customer.email}}
                            </div>
                        </div>
                    </div>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap hidden sm:table-cell">
                    <div class="text-sm leading-5 text-gray-900">{{customer.prefix}}{{customer.mobile}}</div>
                </td>
                <td>
                    <img class="w-6 h-6" src="/assets/images/svg/trash.svg"
                        (click)="cleanCustomer()" />
                </td>
            </tr>
        </tbody>
    </table>

    <div class="flex flex-wrap" [formGroup]="appointmentForm" *ngIf="kindsAppointments">
        <div class="w-full sm:w-1/2 p-2" #flatpickrA>
            <app-label-form for="date" name="{{ 'appointment_create.date' | translate
                }}">
            </app-label-form>
            <ng2-flatpickr  formControlName="date" #calendar addClass="inputClass"
                [config]="dateOptions"
                placeholder="{{ 'appointment_create.date' | translate }}">
            </ng2-flatpickr>
            <ng-container *ngIf="submitted && f.date.errors">
                <app-error-form *ngIf="f.date.errors.required"
                    error="{{'errors.required' |
                    translate}}">
                </app-error-form>
            </ng-container>
        </div>
        <div class="w-full sm:w-1/2 p-2">
            <app-label-form for="duration" name="{{ 'appointment_create.duration' |
                translate }}">
            </app-label-form>
            <input formControlName="duration" readonly class="inputClass" id="duration"
                type="number"
                min="0"
                [ngClass]="{ 'border border-red-500': submitted && f.duration.errors }"
                placeholder="{{ 'appointment_create.duration' | translate }}">
            <ng-container *ngIf="submitted && f.duration.errors">
                <app-error-form *ngIf="f.duration.errors.required"
                    error="{{'errors.required' | translate}}">
                </app-error-form>
            </ng-container>
        </div>
        <div class="w-full sm:w-1/3 p-2">
            <app-label-form for="name" name="{{'kind_appointments.name' | translate}}"></app-label-form>
            <div class="relative">
                <span class="inline-block w-full rounded-md shadow-sm">
                    <button type="button" (click)="showListKind= !showListKind"
                        class="cursor-default relative w-full rounded-md border
                        border-gray-300 bg-white pl-3 pr-10 py-2 text-left
                        focus:outline-none transition ease-in-out duration-150
                        sm:text-sm
                        sm:leading-5">
                        <div class="flex items-center space-x-3">
                            <span aria-label="Online" class="flex-shrink-0 inline-block
                                h-2
                                w-2 rounded-full"
                                [ngStyle]="{'background-color':
                                firstKindAppointment.color}"></span>
                            <span class="block truncate">
                                {{firstKindAppointment.name}}
                            </span>
                        </div>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2
                            pointer-events-none">
                            <img class="h-5 w-5 text-gray-400"
                                src="assets/images/svg/selector.svg" />

                        </span>
                    </button>
                </span>

                <div class="mt-1 w-full rounded-md bg-white shadow-lg"
                    *ngIf="showListKind">
                    <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                        aria-activedescendant="listbox-item-3"
                        class="max-h-60 rounded-md py-1 text-base leading-6 ring-1
                        ring-black ring-opacity-5 overflow-auto focus:outline-none
                        sm:text-sm sm:leading-5">

                        <li id="listbox-item-0" *ngFor="let kinds of kindsAppointments"
                            (click)="changeKind(kinds)"
                            class="cursor-default select-none relative py-2 pl-3 pr-9">
                            <div class="flex items-center space-x-3">
                                <span [ngStyle]="{'background-color': kinds.color}"
                                    class="flex-shrink-0 inline-block h-2 w-2
                                    rounded-full"></span>
                                <span class="font-normal block truncate">
                                    {{kinds.name}}
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="w-full sm:w-1/3 p-2">
            <app-label-form for="user" name="{{ 'appointment_create.user' | translate
                }}">
            </app-label-form>
            <select formControlName="user_id" class="bg-white inputClass" id="user">
                <option hidden selected value="">{{ 'appointment_create.user' |
                    translate }}
                </option>
                <option value="{{ user.id }}" *ngFor="let user of users">
                    {{ user.name }}
                </option>
            </select>
            <ng-container *ngIf="submitted && f.user_id.errors">
                <app-error-form *ngIf="f.user_id.errors.required"
                    error="{{'errors.required' | translate}}">
                </app-error-form>
            </ng-container>
        </div>
        <div class="w-full sm:w-1/3 p-2">
            <app-label-form for="type" name="Type">
            </app-label-form>
            <select formControlName="type" class=" inputClass" id="type">
                <option hidden selected value="">
                    Type
                </option>
                <option value=1>
                    Digital
                </option>
                <option value=2>
                    Shop
                </option>
            </select>
            <ng-container *ngIf="submitted && f.type.errors">
                <app-error-form *ngIf="f.type.errors.required"
                    error="{{'errors.required' | translate}}">
                </app-error-form>
            </ng-container>
        </div>
    </div>
    
    <div class="p-2 flex justify-end" *ngIf="kindsAppointments">
        <button type="submit" (click)="createAppointment();" [disabled]="submitted"
            class="customer-info-button">
            {{text_button_create}}
        </button>
    </div>
</div>