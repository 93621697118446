import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CalendarView, CalendarEvent, DAYS_OF_WEEK } from 'angular-calendar';
import { Observable, Subject, Subscription } from 'rxjs';
import { SettingGeneralService } from 'src/app/shared/services/settings-general.service';
import { AppointmentService } from 'src/app/shared/services/appointment.service';
import * as moment from 'moment';
import { MenuComponent } from '../home/menu/menu.component';
import { AuthenticationGeneralService } from 'src/app/shared/services/auth-general.service';
import { Router } from '@angular/router';
import { TeamService } from 'src/app/shared/services/team.service';
import { LoginService } from 'src/app/auth/login/login.service';

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})

export class CalendarComponent implements OnInit, OnDestroy {

	view: CalendarView = CalendarView.Day;

	locale: string = localStorage.getItem('country');

	weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

	weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

	CalendarView = CalendarView;

	viewDate: Date = new Date();

	refresh: Subject<any> = new Subject();

	events$: Observable<CalendarEvent<{}>[]>;

	currentDay = moment().format('YYYY-MM-DD');

	isOpenView: boolean = false;

	viewName: string;

	dayStartHour: number;
	dayEndHour: number;
	excludeDays: number[];

	rol_id: number;
	private subscription = new Subscription();
	users;

	hourSegments: number = 4;

	constructor(
		public settingGeneralService: SettingGeneralService,
		public appointmentService: AppointmentService,
		public menuComponent: MenuComponent,
		private authService: AuthenticationGeneralService,
		private teamService: TeamService,
		public loginService: LoginService,
		private router: Router,
	) { }

	ngOnInit(): void {
		this.rol_id = this.authService.getUserVariable('rol');
		if (this.rol_id === 1) {
			this.subscription.add(
				this.teamService.listUsersNotAdmin(this.authService.getUserVariable('current_team_id')
			).subscribe(
				(response) => {
					this.users = response;
				}
			))
		}

		this.setStringViewCalendar();

		this.events$ = this.appointmentService.listAppointment(this.currentDay);

		this.dayStartHour = (this.authService.getUserVariable('start_hour')).split(':')[0];
		this.dayEndHour = (this.authService.getUserVariable('finish_hour')).split(':')[0];

		this.excludeDays = this.generateExcludeDays();
	}

	private setStringViewCalendar() {
		let view: CalendarView = CalendarView.Day;
		switch (this.authService.getUserVariable('calendar_view_config')) {
			case 'Week':
				view = CalendarView.Week;
				break;
			case 'Month':
				view = CalendarView.Month;
				break;
		}
		
		this.setView(view);
	}

	generateExcludeDays(): number[] {
		const days = this.authService.getUserVariable('days');

		let excludeDays: number[] = [];
		for (let index = 0; index < 7; index++) {
			if (!days.includes(index)) {
				if (index == 6) {
					excludeDays.push(0);
				} else {
					excludeDays.push(index + 1);
				}
			}
		}

		return excludeDays;
	}

	dayClicked(date?: Date): void {
		this.menuComponent.createAppointment(date)
	}

	eventClicked(event) {
		if(event.event.customer_id)  {
			this.router.navigate(['customers/' + event.event.customer_id]);
		}
	}

	setView(view: CalendarView): void {
		this.isOpenView = false;
		this.view = view;
		this.viewName = this.settingGeneralService.getLangText('calendar.' + view);
	}

	closeOpenMonthViewDay(): void {
		const newDay = moment(this.viewDate).format('YYYY-MM-DD');

		if (moment(newDay).format('MM') != moment(this.currentDay).format('MM')) {
			this.currentDay = newDay;

			this.events$ = this.appointmentService.listAppointment(newDay);
		}

	}

	showUserCalendar(user_id?: number) {
		this.events$ = this.appointmentService.listAppointment(this.currentDay, user_id);
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

}
