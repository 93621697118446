<div ngClass="{{class}}" [formGroup]="customerForm" *ngIf="customerForm; else loading;">
    <div class="col-span-2 lg:col-span-1">
        <app-label-form for="name" name="{{ 'customer_create.name' | translate }}"></app-label-form>
        <input formControlName="name" class="inputClass" id="name" type="text"
            [ngClass]="{ 'border border-red-500': submitted && f.name.errors }"
            placeholder="{{ 'customer_create.name' | translate }}">
        <ng-container *ngIf="submitted && f.name.errors">
            <app-error-form *ngIf="f.name.errors.required" error="{{'errors.required' |
                translate}}">
            </app-error-form>
            <app-error-form *ngIf="f.name.errors.maxlength"
                error="{{'errors.maxLength' | translate:{number:
                validationMaxString.short_string} }}">
            </app-error-form>
        </ng-container>
    </div>

    <div class="col-span-2 lg:col-span-1">
        <app-label-form for="name" name="{{ 'customer_create.surnames' | translate }}">
        </app-label-form>

        <input formControlName="surnames" class="inputClass" id="surnames" type="text"
            [ngClass]="{ 'border border-red-500': submitted && f.surnames.errors }"
            placeholder="{{ 'customer_create.surnames' | translate }}">
        <ng-container *ngIf="submitted && f.surnames.errors">
            <app-error-form *ngIf="f.surnames.errors.required" error="{{'errors.required' |
                translate}}">
            </app-error-form>
            <app-error-form *ngIf="f.surnames.errors.maxlength"
                error="{{'errors.maxLength' | translate:{number:
                validationMaxString.long_string} }}">
            </app-error-form>
        </ng-container>
    </div>

    <div class="col-span-2 lg:col-span-1">
        <app-label-form for="mobile" name="{{ 'customer_create.mobile' | translate }}">
        </app-label-form>
        <input formControlName="mobile" class="inputClass" id="mobile" 
            [ngClass]="{ 'border border-red-500': submitted && f.mobile.errors }"
            type="number" placeholder="{{ 'customer_create.mobile' | translate }}" min="0">

        <ng-container *ngIf="submitted && (f.mobile.errors || f.prefix.errors)">
            <app-error-form error="{{'errors.required' | translate}}">
            </app-error-form>
        </ng-container>
    </div>

    <div class="col-span-2 lg:col-span-1">
        <app-label-form for="email" name="{{ 'customer_create.email' | translate }}">
        </app-label-form>
        <input formControlName="email" class="inputClass" id="email" type="text"
            [ngClass]="{ 'border border-red-500': submitted && f.email.errors }"
            placeholder="{{ 'customer_create.email' | translate }}">
        <ng-container *ngIf="submitted && f.email.errors">
            <app-error-form *ngIf="f.email.errors.required" error="{{'errors.required' |
                translate}}">
            </app-error-form>
            <app-error-form *ngIf="f.email.errors.email" error="{{'errors.email' |
                translate}}">
            </app-error-form>
            <app-error-form *ngIf="f.email.errors.maxlength"
                error="{{'errors.maxLength' | translate:{number:
                validationMaxString.long_string} }}">
            </app-error-form>
        </ng-container>
    </div>

    <div class="col-span-2 lg:col-span-1">
        <app-label-form for="supplement" name="{{ 'customer_create.supplement' | translate
            }}">
        </app-label-form>
        <select [ngModel]="supplement"
            [ngModelOptions]="{standalone: true}"
            (ngModelChange)="onChangeSelect('supplement', $event)" class="bg-white inputClass"
            [ngClass]="{ 'border border-red-500': submitted && f.supplement.errors }"
            id="supplement">
            <option hidden selected value="0">{{ 'customer_create.supplement' | translate }}</option>
            <option value="{{ option.id }}" *ngFor="let option of options">{{ option.text }}
            </option>
        </select>
        <ng-container *ngIf="submitted && f.supplement.errors">
            <app-error-form *ngIf="f.supplement.errors.required || f.supplement.errors.min"
                error="{{'errors.required' | translate}}">
            </app-error-form>
        </ng-container>
    </div>

    <div class="col-span-2 lg:col-span-1">
        <app-label-form for="illness" name="{{ 'customer_create.illness' | translate }}">
        </app-label-form>
        <select [ngModel]="illness"
            [ngModelOptions]="{standalone: true}"
            (ngModelChange)="onChangeSelect('illness', $event)" class="bg-white inputClass"
            [ngClass]="{ 'border border-red-500': submitted && f.illness.errors }"
            id="illness">
            <option hidden selected value="0">{{ 'customer_create.illness' | translate }}</option>
            <option value="{{ illness.id }}" *ngFor="let illness of illnesses">{{
                illness.text }}
            </option>
        </select>
        <ng-container *ngIf="submitted && f.illness.errors">
            <app-error-form *ngIf="f.illness.errors.required || f.illness.errors.min"
                error="{{'errors.required' | translate}}">
            </app-error-form>
        </ng-container>
    </div>

    <div class="col-span-2">
        <div class="grid grid-cols-3 gap-7">
            <div class="col-span-3 lg:col-span-1">
                <app-label-form for="zip" name="{{ 'customer_create.zip' | translate }}"></app-label-form>
                <input (focusout)="findZipcode($event)" formControlName="zip" class="inputClass" id="zip"
                type="{{zipcodesConfig.acceptAlpha ? 'text': 'number'}}" 
                maxlength="{{zipcodesConfig.length}}" [ngClass]="{ 'border border-red-500': submitted && f.zip.errors }"
                    placeholder="{{ 'customer_create.zip' | translate }}" min="0">
                
                <ng-container *ngIf="submitted && f.zip.errors">
                    <app-error-form *ngIf="f.zip.errors.required" error="{{'errors.required' |
                        translate}}">
                    </app-error-form>
                </ng-container>
                <ng-container *ngIf="noCpError">
                    <app-error-form error="Busque por su código postal">
                    </app-error-form>
                </ng-container>
                <ng-container *ngIf="prohibitedError">
                    <p class="text-red-500 text-xs italic" innerHTML="{{'errors.prohibitedCpError' | translate}}">
                    </p>
                </ng-container>
            </div>
            <div class="col-span-3 lg:col-span-1">
                <app-label-form for="dob" name="{{ 'customer_create.dob' | translate }}"></app-label-form>
                <ng2-flatpickr formControlName="dob" #calendar addClass="inputClass"
                    [config]="dateOptions"
                    (change)="changeDob($event)" placeholder="{{ 'customer_create.dob' |
                    translate
                    }}">
                </ng2-flatpickr>
                <ng-container *ngIf="submitted && f.dob.errors">
                    <app-error-form *ngIf="f.dob.errors.required" error="{{'errors.required' |
                        translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 lg:col-span-1">
                <app-label-form for="gender" name="{{ 'customer_create.gender' | translate
                    }}">
                </app-label-form>
                <select [ngModel]="gender"
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="onChangeSelect('gender', $event)" class="bg-white inputClass"
                    [ngClass]="{ 'border border-red-500': submitted && f.gender.errors }"
                    id="gender">
                    <option hidden selected value="0">{{ 'customer_create.gender' |
                        translate }}</option>
                    <option *ngFor="let gender of genders" [value]=gender.id>
                        {{ gender.text }}
                    </option>
                </select>
                <ng-container *ngIf="submitted && f.gender.errors">
                    <app-error-form *ngIf="f.gender.errors.required || f.gender.errors.min"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="col-span-2" *ngIf="under">
        <div class="grid grid-cols-3 gap-7">
            <div class="col-span-3 lg:col-span-1">
                <app-label-form for="email" name="{{ 'customer_create.legal_name' |
                    translate }}">
                </app-label-form>
                <input formControlName="legal_name" class="inputClass" id="legal_name"
                    type="text"
                    [ngClass]="{ 'border border-red-500': submitted && f.legal_name.errors
                    }"
                    placeholder="{{ 'customer_create.legal_name' | translate }}">
                <ng-container *ngIf="submitted && f.legal_name.errors">
                    <app-error-form *ngIf="f.legal_name.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                    <app-error-form *ngIf="f.legal_name.errors.maxlength"
                        error="{{'errors.maxLength' | translate:{number:
                        validationMaxString.long_string} }}">
                    </app-error-form>
                </ng-container>
            </div>

            <div class="col-span-3 lg:col-span-1">
                <app-label-form for="email" name="{{ 'customer_create.legal_surnames' |
                    translate }}">
                </app-label-form>
                <input formControlName="legal_surnames" class="inputClass"
                    id="legal_surnames" type="text"
                    [ngClass]="{ 'border border-red-500': submitted &&
                    f.legal_surnames.errors }"
                    placeholder="{{ 'customer_create.legal_surnames' | translate }}">
                <ng-container *ngIf="submitted && f.legal_surnames.errors">
                    <app-error-form *ngIf="f.legal_surnames.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                    <app-error-form *ngIf="f.legal_surnames.errors.maxlength"
                        error="{{'errors.maxLength' | translate:{number:
                        validationMaxString.long_string} }}">
                    </app-error-form>
                </ng-container>
            </div>

            <div class="col-span-3 lg:col-span-1">
                <app-label-form for="email" name="{{ 'customer_create.legal_identity' |
                    translate }}">
                </app-label-form>
                <input formControlName="legal_identity" class="inputClass"
                    id="legal_identity" type="text"
                    [ngClass]="{ 'border border-red-500': submitted &&
                    f.legal_identity.errors }"
                    placeholder="{{ 'customer_create.legal_identity' | translate }}">
                <ng-container *ngIf="submitted && f.legal_identity.errors">
                    <app-error-form *ngIf="f.legal_identity.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                    <app-error-form *ngIf="f.legal_identity.errors.maxlength"
                        error="{{'errors.maxLength' | translate:{number:
                        validationMaxString.long_string} }}">
                    </app-error-form>
                </ng-container>
            </div>

            <!--  -->
        </div>
    </div>

    <div class="col-span-2" *ngIf="!term">
        <div class="grid grid-cols-1 gap-7">
            <div class="col-span-1 lg:col-span-1">
                <app-label-form for="type" name="Type">
                </app-label-form>
                <select [ngModel]="type"
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="onChangeSelect('type', $event)" class="bg-white inputClass"
                    [ngClass]="{ 'border border-red-500': submitted && f.type.errors }"
                    id="type">
                    <option value=1>
                        Digital
                    </option>
                    <option value=2>
                        Shop
                    </option>
                </select>
                <ng-container *ngIf="submitted && f.type.errors">
                    <app-error-form *ngIf="f.type.errors.required || f.type.errors.min"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-1 lg:col-span-1" *ngIf="tags && tags.length > 0 && customer">
                <app-label-form for="gender" name="Tags">
                </app-label-form>
                <div class="flex space-x-2 overflow-x-auto pb-3">
                    <span [ngClass]="{ 'bg-nutriwell': tag.selected, 'bg-nutriwell-stone': !tag.selected}" (click)="addRemoveTagId(tag)" *ngFor="let tag of tags" class="badge">{{tag.name}}</span>
                </div>
            </div>
        </div>
        
    </div>

    <div class="col-span-2" *ngIf="term">
        <div class="relative flex items-start">
            <div class="flex items-center h-5">
                <input id="comments" name="comments" type="checkbox"
                    formControlName="terms"
                    class="focus:ring-nutriwell h-4 w-4 text-nutriwell border-gray-300
                    rounded">
            </div>
            <div class="ml-3 text-sm">
                <label for="comments" class="font-medium text-gray-700 cursor-pointer"
                    (click)="showTerms()">
                    <span innerHTML="{{ 'customer_create.terms' | translate }}"></span>
                </label>
            </div>

        </div>
        <ng-container *ngIf="submitted && f.terms.errors">
            <app-error-form *ngIf="f.terms.errors.required"
                error="{{'errors.required' | translate}}">
            </app-error-form>
        </ng-container>
    </div>

    <div *ngIf="in_my_team" class="col-span-2 flex flex-row-reverse">
        <span class="shadow-sm rounded-md">
            <button type="button" class="customer-info-button-reverse" (click)="onSubmit()">
                <img class="customer-info-button-svg"
                    src="assets/images/svg/check-white.svg" />

                {{ 'customer_edit.save' | translate }}
            </button>
        </span>
    </div>
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>