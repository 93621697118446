<div class="py-6 sm:px-6 lg:px-8">
    <div class="pb-6">
        <span class="relative z-10 inline-flex shadow-sm rounded-md float-left">
            <button type="button" class="middle-button rounded-l-md" mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                <img class="h-5 w-5" src="assets/images/svg/chevron-left.svg" alt="chevron">
            </button>
            <button type="button" class="-ml-px middle-button" mwlCalendarToday
                [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()">
                {{ 'calendar.today' | translate }}
            </button>
            <button type="button" class="-ml-px middle-button rounded-r-md" mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                <img class="h-5 w-5" src="assets/images/svg/chevron-right.svg" alt="chevron">
            </button>
        </span>
        <span class="relative z-10 inline-flex shadow-sm rounded-md float-right">
            <button type="button" class="middle-button rounded-l-md">
                {{ viewName ? viewName : 'calendar.day' | translate }}
            </button>
            <span class="-ml-px relative block">
                <button type="button" class="middle-button rounded-r-md" aria-label="Expand"
                    (click)="isOpenView= !isOpenView">

                    <img class="h-5 w-5" src="assets/images/svg/chevron-down.svg" alt="check">
                </button>

                <div class="origin-top-right absolute right-0 mt-2 -mr-1 w-32 rounded-md
                    shadow-lg transition ease-out duration-100"
                    [ngClass]="{ 'hidden': !isOpenView }">
                    <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
                        <div class="py-1">
                            <a class="openViewbutton"
                                (click)="setView(CalendarView.Month)">
                                {{ 'calendar.month' | translate }}
                            </a>
                            <a class="openViewbutton"
                                (click)="setView(CalendarView.Week)">
                                {{ 'calendar.week' | translate }}
                            </a>
                            <a class="openViewbutton"
                                (click)="setView(CalendarView.Day)">
                                {{ 'calendar.day' | translate }}
                            </a>
                        </div>
                    </div>
                </div>
            </span>
        </span>
        <div class="text-center">
            <h3 style="text-transform: capitalize;">
                {{ viewDate | calendarDate:(view + 'ViewTitle'):
                (settingGeneralService.changeCountry$ | async).locale:
                (settingGeneralService.changeCountry$ | async).weekStartsOn }}
            </h3>
        </div>
    </div>
</div>
<div class="py-6 sm:px-6 lg:px-8">
    <div class="flex -space-x-2 float-left" *ngIf="users">
        <img class="img-profile cursor-pointer"
            [src]='(loginService.imgProfileSource$ | async)'
            (click)="showUserCalendar()" />
        <img *ngFor="let user of users" class="img-profile"
            src="{{user.image}}"
            (click)="showUserCalendar(user.id)" />
    </div>
    <div class="flex -space-x-2 float-right">
        <button type="button" class="customer-info-button-white" (click)="dayClicked()">
            <img class="customer-info-button-svg text-gray-500"
                src="assets/images/svg/plus.svg" />
            {{ 'tag_list.create.text_button_create' | translate}}
        </button>
    </div>
</div>

<div class="py-6 sm:px-6 lg:px-8">
    <ng-template #loading>
        <app-loading></app-loading>
    </ng-template>

    <ng-template #eventTemplateWeek let-weekEvent="weekEvent" let-locale="locale">
        <div style="width: calc(100% - 2px); height: calc(100% - 2px); margin: 1px; padding: 0 5px; line-height: 25px; 
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;" (click)="eventClicked(weekEvent)"
        class="flex flex-row justify-around rounded" [style.background-color]="weekEvent.event.color.primary">
            <img *ngIf="weekEvent.event.typeA === 1" src="assets/images/svg/phone.svg" class="w-5" />
            <img *ngIf="weekEvent.event.typeA === 2" src="assets/images/svg/home.svg" class="w-5" />
              
            <span class="text-white" style="font-size: 10px;">{{weekEvent.event.title}}</span>

            <img *ngIf="weekEvent.event.status && weekEvent.event.customer_id" src="assets/images/svg/status.svg" class="w-5" />
            <img *ngIf="weekEvent.event.confirm && weekEvent.event.customer_id" src="assets/images/svg/confirm.svg" class="w-5" />
        </div>
    </ng-template>
    
    <ng-template #eventTemplateDay let-weekEvent="weekEvent" let-locale="locale">
        <div style="width: calc(50% - 2px); height: calc(100% - 2px); margin: 1px; padding: 0 5px; line-height: 25px; 
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;"  (click)="eventClicked(weekEvent)"
        class="flex flex-row justify-evenly rounded" [style.background-color]="weekEvent.event.color.primary">
            <img *ngIf="weekEvent.event.typeA === 1" src="assets/images/svg/phone.svg" class="w-5" />
            <img *ngIf="weekEvent.event.typeA === 2" src="assets/images/svg/home.svg" class="w-5" />
            
            <span class="text-white" style="font-size: 10px;">{{weekEvent.event.title}}</span>

            <img *ngIf="weekEvent.event.status && weekEvent.event.customer_id" src="assets/images/svg/status.svg" class="w-5" />
        </div>
        
    </ng-template>
   <ng-container *ngIf="events$ | async; else loading; let events">
        <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
                [viewDate]="viewDate" [events]="events"
                style="text-transform: capitalize;"
                [locale]="(settingGeneralService.changeCountry$ | async).locale"
                [weekStartsOn]="(settingGeneralService.changeCountry$ |
                async).weekStartsOn"
                [weekendDays]="(settingGeneralService.changeCountry$ |
                async).weekendDays" [refresh]="refresh"
                (dayClicked)="dayClicked($event.day.date)"
                (eventClicked)="eventClicked($event)"
                [excludeDays]="excludeDays">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week"
                [viewDate]="viewDate" [events]="events"
                style="text-transform: capitalize;"
                [locale]="(settingGeneralService.changeCountry$ | async).locale"
                [weekStartsOn]="(settingGeneralService.changeCountry$ |
                async).weekStartsOn"
                [weekendDays]="(settingGeneralService.changeCountry$ |
                async).weekendDays" [refresh]="refresh"
                (hourSegmentClicked)="dayClicked($event.date)"
                (eventClicked)="eventClicked($event)" 
                [excludeDays]="excludeDays" 
                [dayStartHour]="dayStartHour"
                [dayEndHour]="dayEndHour"
                [hourSegments]="hourSegments"
                [eventTemplate]="eventTemplateWeek">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day"
                [viewDate]="viewDate" [events]="events"
                style="text-transform: capitalize;"
                [locale]="(settingGeneralService.changeCountry$ | async).locale"
                 [refresh]="refresh"
                (hourSegmentClicked)="dayClicked($event.date)"
                (eventClicked)="eventClicked($event)"
                [dayStartHour]="dayStartHour"
                [dayEndHour]="dayEndHour"
                [hourSegments]="hourSegments"
                [eventTemplate]="eventTemplateDay">
            </mwl-calendar-day-view>
        </div>
    </ng-container>

</div>