export class Appointment {

    public customer_id: number;
    public duration: number;
    public team_id: number;
    public user_id: number;
    public date: any;
    public hour: any;
    public kind_appointment_id: number;
    public type: number;

    constructor(customer_id, duration, team_id, user_id, date, hour, kind_appointment_id, type) {
        this.customer_id = customer_id;
        this.duration = duration;
        this.team_id = team_id;
        this.user_id = user_id;
        this.date = date;
        this.hour = hour;
        this.kind_appointment_id = kind_appointment_id;
        this.type = type;
    }
}