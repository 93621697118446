import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Spanish from 'flatpickr/dist/l10n/es.js';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Observable, Subscription } from 'rxjs';
import { CustomerList } from 'src/app/shared/interfaces/customers.interface';
import { CustomerService } from 'src/app/shared/services/customer.service';
import * as moment from 'moment';
import { SettingGeneralService } from 'src/app/shared/services/settings-general.service';
import { User } from 'src/app/shared/interfaces/user.interface';
import { TeamService } from 'src/app/shared/services/team.service';
import { Appointment } from 'src/app/shared/interfaces/appointment.interface';
import { UtilsService } from 'src/app/shared/services/util.service';
import { AuthenticationGeneralService } from 'src/app/shared/services/auth-general.service';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { AppointmentService } from 'src/app/shared/services/appointment.service';
import { SettingService } from 'src/app/shared/services/setting.service';
import { CurrentFilter, Filter } from 'src/app/shared/interfaces/filter.interface';


@Component({
    selector: 'app-appointment',
    templateUrl: './appointment.component.html'
})

export class AppointmentComponent implements OnInit, OnDestroy {

    @Input() appointmentDay;
    @Input() customerI;
    @Input() text_button_create: string;

    searchText: FormControl;
    customers: CustomerList[];
    customer: CustomerList;
    showCustomers: string = 'none';
    subscription: Subscription = new Subscription;

    appointmentForm: FormGroup;
    submitted = false;
    
    dateOptions: FlatpickrOptions = {
        locale: Spanish.es,
        dateFormat: 'd-m-Y H:i',
        disableMobile: true,
        enableTime: true,
        minuteIncrement: 15,
        onReady: function(selectedDates, dateStr, instance) {
            let inputs = instance.calendarContainer.getElementsByClassName("flatpickr-minute");
            Array.from(inputs).forEach((el: any) =>  el.setAttribute('readonly', 'true'));
        },
    };
    users: User[];

    current_team_id: number;

    kindsAppointments;
    kindsDurationAppointments;
    firstKindAppointment;
    firstDurationAppointment;
    showListKind = false;

    @ViewChild('scroll') private myScrollContainer: ElementRef;
    noMore: boolean = false;
    loadingMore: boolean = false;

    filters: Filter[];
    filter: CurrentFilter[] = null;

    // pagination
    page = 1;
    per_page = 15;
    from: number;
    to: number;
    total: number;
    current_page: number;
    first_page: boolean = true;
    last_page: boolean = false;
    links: [];

    loadingShow: boolean = false;
    listCustomer$ = Observable;

    showMenuI = false;
    
    constructor(
        private builder: FormBuilder,
        private customerService: CustomerService,
        public settingGeneralService: SettingGeneralService,
        private teamService: TeamService,
        private utilService: UtilsService,
        private authService: AuthenticationGeneralService,
        private snackbarService: SnackbarService,
        private appointmentService: AppointmentService,
        private settingService: SettingService
    ) { }

    ngOnInit(): void {

        this.appointmentService.listAppointmentFilters(this.authService.getUserVariable('current_team_id')).subscribe(
            (response: Filter[]) =>  {
                this.filters = response;

                this.filters.forEach((filter) => {
                    filter.name = filter.name;
                    filter.nameTranslate = 'customer_list.table.filters.'+filter.name;
                });
            }
        );
            

        if (this.customerI) {
            this.customer = this.customerI;
        }

        let day = '';
        if (this.appointmentDay) {
            day = moment(this.appointmentDay).format('YYYY-MM-DD HH:mm');
            this.dateOptions.defaultDate = moment(this.appointmentDay).format('DD-MM-YYYY HH:mm');
        }

        this.current_team_id = this.authService.getUserVariable('current_team_id');

        this.appointmentForm = this.builder.group({
            customer_id: [this.customerI ? this.customerI.id : '', [Validators.required]],
            date: [{ 0: day }, [Validators.required]],
            duration: [20, [Validators.required]],
            team_id: [{ value: this.current_team_id, disabled: true }, [Validators.required]],
            user_id: [{ value: this.authService.getUserVariable('id') }, [Validators.required]],
            type: [2, [Validators.required]],
        });

        this.subscription.add(this.settingGeneralService.changeCountry$.subscribe(
            (settings) => {
                this.dateOptions.locale = settings.flatpickr;
            }
        ));

        this.subscription.add(this.teamService.listUsers(this.current_team_id).subscribe(
            (response) => {
                this.users = response;
                this.appointmentForm.controls['user_id'].setValue('');
                this.subscription.add(this.settingService.getColors(this.current_team_id).subscribe(
                    (response) => {
                        this.kindsAppointments = response;
                        const appointKindNew = this.kindsAppointments.filter(
                            kindAppointment => kindAppointment.kind_appointment_id == 4);
                            
                            this.firstKindAppointment = appointKindNew[0]
                    }
                ));

                this.subscription.add(this.settingService.getAppointmentsDuration(this.current_team_id).subscribe(
                    (response) => {
                        this.kindsDurationAppointments = response;
                        
                        const appointKindNew = this.kindsDurationAppointments.filter(
                            kindAppointment => kindAppointment.kind_appointment_id == 4);
                            
                        this.firstDurationAppointment = appointKindNew[0];
                        this.appointmentForm.controls['duration'].setValue(this.firstDurationAppointment.duration);
                    }
                ));
            }
        ));
    }

    filterChange(filters: CurrentFilter[]) {
        this.filter = filters;
        this.page = 1;
        try {
            this.myScrollContainer.nativeElement.scroll(0,0);
        } catch(err) { } 

        if(this.filter.length !== 0) {
            this.listCustomer(this.filter);
        } else {
            this.cleanCustomers();
        }
        
    }
    showMenu() {
        this.showMenuI = !this.showMenuI;
    }

    // scroll
    onScroll(event) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !this.noMore) {
            this.loadingMore = true;

            this.page++;
            this.listCustomer(this.filter, true);
        }
    }

    get f() { return this.appointmentForm.controls; }

    listCustomer(query?, scroll = false) {
        this.subscription.add(
            this.customerService.listCustomer(this.page, this.per_page, query).subscribe(
                (response) => {
                    if(!scroll) {
                        this.customers = response.data;

                        if(this.customers.length > 0) {
                            this.noMore = false;
                        } else {
                            this.noMore = true;
                        }
                    } else {
                        const moreCustomers = response.data;

                        this.loadingMore = false;
                        if(0 === moreCustomers.length) {
                            this.noMore = true;
                            return ;
                        }

                        this.customers = this.customers.concat(moreCustomers);
                    }
                }
            )
        );
    }

    cleanCustomers() {
        this.customers = [];
        this.showCustomers = 'none';
    }

    selectCustomer(customer) {
        this.cleanCustomers();
        this.customer = customer;
        this.appointmentForm.controls['customer_id'].setValue(this.customer.id);
    }

    cleanCustomer() {
        this.customer = null;
        this.appointmentForm.reset();
        this.appointmentForm.controls['team_id'].setValue(this.current_team_id);
        this.submitted = false;
    }

    createAppointment() {
        this.submitted = true;

        if (this.appointmentForm.invalid) {
            return;
        }

        let appointment: Appointment = this.utilService.clear(this.appointmentForm.value);

        let appointmentDate = this.appointmentForm.controls['date'].value;
        if(typeof appointmentDate === 'object') {
            appointmentDate = appointmentDate[0];
        }
        

        const hour = moment(appointmentDate).format('HH:mm');
        appointment.date = moment(appointmentDate).format('YYYY-MM-DD');
        appointment.hour = hour;
        appointment.team_id = this.appointmentForm.controls['team_id'].value;
        appointment.kind_appointment_id = this.firstKindAppointment.kind_appointment_id;
        this.subscription.add(this.appointmentService.saveAppointment(appointment).subscribe(
            (response) => {
                this.showSnackBar('Appointment created successfully.', 'success');
                window.location.reload();
            },
            (error) => {
                this.showSnackBar('Algo ha pasado ....', 'danger');
            }
        ));
    }

    showSnackBar(text: string, _class: string) {
        this.snackbarService.show(text, _class);
    }

    changeKind(kind) {
        this.showListKind = false;
        this.firstKindAppointment = kind;
        const duration = this.kindsDurationAppointments.filter((duration) => duration.kind_appointment_id === kind.kind_appointment_id)[0];
        this.appointmentForm.controls['duration'].setValue(duration.duration);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}