<div class="py-3 flex items-center justify-between border-t border-gray-200" *ngIf="filters">
    <div class="sm:flex-1 sm:flex sm:items-center sm:justify-between">
       <div class="relative inline-block text-left">
            <span (click)="showColumns = false; showFilters = !showFilters; showFiltersValue = false" 
                class="cursor-pointer inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-nutriwell text-white">
                Filtros
            </span>

            <ng-container *ngIf="currentFilters.length > 0">
                <span *ngFor="let current of currentFilters; let i = index" 
                    class="mx-1.5 inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-nutriwell text-white">
                    {{current.nameText}}: {{current.valueText}}
                    <button (click)="deleteCurrentFilter(i)" type="button" 
                    class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-white focus:outline-none focus:bg-nutriwell-sansivera focus:text-white">
                        <span class="sr-only">Remove small option</span>
                        <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                    </button>
                </span>
            </ng-container>

            <div *ngIf="showFilters" [@enterAnimation] class="overflow-y-auto z-50 text-left origin-top-right absolute left-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="m-1" role="none">
                    <a (click)="filterValue(filter)" class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:bg-nutriwell hover:text-white" role="menuitem" tabindex="-1" id="menu-item-0" *ngFor="let filter of filters">
                        {{filter.nameTranslate | translate}}
                    </a>
                </div>
            </div>

            <div *ngIf="showFiltersValue" [@enterAnimationValue] class="text-left z-50 top-0 absolute left-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="m-1" role="none">
                    <ng-container *ngIf="filter.type !== 'text'">
                        <a *ngFor="let option of objectKeys(filter.options); let i = index" (click)="selectFilterValue(option)" class="cursor-pointer text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0" >
                            {{filter.options[option]}}
                        </a>
                    </ng-container>
                    <ng-container *ngIf="filter.type === 'text'">
                        <div class="w-full flex rounded-md shadow-sm">
                            <input [(ngModel)]="filterText" type="text" name="company-website" id="company-website" class="inputClass">
                            <span (click)="selectFilterValue(filterText)" class="cursor-pointer inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"> 
                                <img class="w-6 h-6" src="assets/images/svg/check.svg" />
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>