<app-modal-data [title]="title" (closeModal)="closeModalF()">
    <div data class="w-full" *ngIf="readyForShow">
        <div class="flex flex-wrap" [formGroup]="newappointmentsForm" *ngIf="kindsAppointments">
            <div class="w-full sm:w-1/2 p-2">
                <app-label-form for="newAppointment7days" name="{{'next_appointments.date' | translate:sevenDays}}">
                </app-label-form>
                <ng2-flatpickr formControlName="newAppointment7days" #calendar addClass="inputClass"
                    [config]="dateOptions7days"
                    placeholder="{{ 'appointment_create.date' | translate }}">
                </ng2-flatpickr>
                <ng-container *ngIf="submitted && f.newAppointment7days.errors">
                    <app-error-form *ngIf="f.newAppointment7days.errors.required"
                        error="{{'errors.required' |
                        translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="w-full sm:w-1/2 p-2">
                <app-label-form for="newAppointment14days" name="{{'next_appointments.date' | translate:fifteenDays}}">
                </app-label-form>
                <ng2-flatpickr formControlName="newAppointment14days" #calendar addClass="inputClass"
                    [config]="dateOptions14days"
                    placeholder="{{ 'appointment_create.date' | translate }}">
                </ng2-flatpickr>
                <ng-container *ngIf="submitted && f.newAppointment14days.errors">
                    <app-error-form *ngIf="f.newAppointment14days.errors.required"
                        error="{{'errors.required' |
                        translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="w-full sm:w-1/2 p-2">
                <app-label-form for="name" name="{{'next_appointments.type' | translate}}"></app-label-form>
                <div class="relative">
                    <span class="inline-block w-full rounded-md shadow-sm">
                        <button type="button" (click)="showListKind7days= !showListKind7days"
                            class="cursor-default relative w-full rounded-md border
                            border-gray-300 bg-white pl-3 pr-10 py-2 text-left
                            focus:outline-none transition ease-in-out duration-150
                            sm:text-sm
                            sm:leading-5">
                            <div class="flex items-center space-x-3">
                                <span aria-label="Online" class="flex-shrink-0 inline-block
                                    h-2
                                    w-2 rounded-full"
                                    [ngStyle]="{'background-color':
                                    firstKindAppointment7days.color}"></span>
                                <span class="block truncate">
                                    {{firstKindAppointment7days.name}}
                                </span>
                            </div>
                            <span class="absolute inset-y-0 right-0 flex items-center pr-2
                                pointer-events-none">
                                <img class="h-5 w-5 text-gray-400"
                                    src="assets/images/svg/selector.svg" />
    
                            </span>
                        </button>
                    </span>
    
                    <div class="mt-1 w-full rounded-md bg-white shadow-lg"
                        *ngIf="showListKind7days">
                        <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                            aria-activedescendant="listbox-item-3"
                            class="max-h-60 rounded-md py-1 text-base leading-6 ring-1
                            ring-black ring-opacity-5 overflow-auto focus:outline-none
                            sm:text-sm sm:leading-5">
    
                            <li id="listbox-item-0" *ngFor="let kinds of kindsAppointments"
                            (click)="changeKind(kinds, true)"
                                class="cursor-default select-none relative py-2 pl-3 pr-9">
                                <div class="flex items-center space-x-3">
                                    <span [ngStyle]="{'background-color': kinds.color}"
                                        class="flex-shrink-0 inline-block h-2 w-2
                                        rounded-full"></span>
                                    <span class="font-normal block truncate">
                                        {{kinds.name}}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="w-full sm:w-1/2 p-2">
                <app-label-form for="name" name="{{'next_appointments.type' | translate}}"></app-label-form>
                <div class="relative">
                    <span class="inline-block w-full rounded-md shadow-sm">
                        <button type="button" (click)="showListKind14days= !showListKind14days"
                            class="cursor-default relative w-full rounded-md border
                            border-gray-300 bg-white pl-3 pr-10 py-2 text-left
                            focus:outline-none transition ease-in-out duration-150
                            sm:text-sm
                            sm:leading-5">
                            <div class="flex items-center space-x-3">
                                <span aria-label="Online" class="flex-shrink-0 inline-block
                                    h-2
                                    w-2 rounded-full"
                                    [ngStyle]="{'background-color':
                                    firstKindAppointment14days.color}"></span>
                                <span class="block truncate">
                                    {{firstKindAppointment14days.name}}
                                </span>
                            </div>
                            <span class="absolute inset-y-0 right-0 flex items-center pr-2
                                pointer-events-none">
                                <img class="h-5 w-5 text-gray-400"
                                    src="assets/images/svg/selector.svg" />
                            </span>
                        </button>
                    </span>
    
                    <div class="mt-1 w-full rounded-md bg-white shadow-lg"
                        *ngIf="showListKind14days">
                        <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                            aria-activedescendant="listbox-item-3"
                            class="max-h-60 rounded-md py-1 text-base leading-6 ring-1
                            ring-black ring-opacity-5 overflow-auto focus:outline-none
                            sm:text-sm sm:leading-5">
    
                            <li id="listbox-item-0" *ngFor="let kinds of kindsAppointments"
                            (click)="changeKind(kinds, false)"
                                class="cursor-default select-none relative py-2 pl-3 pr-9">
                                <div class="flex items-center space-x-3">
                                    <span [ngStyle]="{'background-color': kinds.color}"
                                        class="flex-shrink-0 inline-block h-2 w-2
                                        rounded-full"></span>
                                    <span class="font-normal block truncate">
                                        {{kinds.name}}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="w-full sm:w-1/2 p-2">
                <app-label-form for="duration7days" name="{{ 'appointment_create.duration' |
                    translate }}">
                </app-label-form>
                <input formControlName="duration7days" class="inputClass" id="duration"
                    type="number"
                    min="0"
                    [ngClass]="{ 'border border-red-500': submitted && f.duration7days.errors }"
                    placeholder="{{ 'appointment_create.duration' | translate }}">
                <ng-container *ngIf="submitted && f.duration7days.errors">
                    <app-error-form *ngIf="f.duration7days.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="w-full sm:w-1/2 p-2">
                <app-label-form for="duration14days" name="{{ 'appointment_create.duration' |
                    translate }}">
                </app-label-form>
                <input formControlName="duration14days" class="inputClass" id="duration14days"
                    type="number"
                    min="0"
                    [ngClass]="{ 'border border-red-500': submitted && f.duration14days.errors }"
                    placeholder="{{ 'appointment_create.duration' | translate }}">
                <ng-container *ngIf="submitted && f.duration14days.errors">
                    <app-error-form *ngIf="f.duration14days.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="w-full sm:w-1/2 p-2">
                <app-label-form for="type" name="Type">
                </app-label-form>
                <select formControlName="type7days" class=" inputClass" id="type">
                    <option hidden selected value="">
                        Type
                    </option>
                    <option value=1>
                        Digital
                    </option>
                    <option value=2>
                        Shop
                    </option>
                </select>
                <ng-container *ngIf="submitted && f.type7days.errors">
                    <app-error-form *ngIf="f.type7days.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="w-full sm:w-1/2 p-2">
                <app-label-form for="type" name="Type">
                </app-label-form>
                <select formControlName="type14days" class=" inputClass" id="type">
                    <option hidden selected value="">
                        Type
                    </option>
                    <option value=1>
                        Digital
                    </option>
                    <option value=2>
                        Shop
                    </option>
                </select>
                <ng-container *ngIf="submitted && f.type14days.errors">
                    <app-error-form *ngIf="f.type14days.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
       </div>
    </div>
    
    <button type="submit" (click)="submit()" button
        class="customer-info-button">
        {{'next_appointments.button' | translate}}
    </button>   
</app-modal-data>