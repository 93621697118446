<app-modal-data [title]="title" (closeModal)="closeModalF()">

    <div data class="flex flex-wrap w-full">
        <div class="flex flex-wrap gap-10 justify-center w-full" *ngIf="!customerI">
            <button type="button" class="customer-info-button"
                (click)="type=1">
                <img src="assets/images/svg/check.svg"
                    class="customer-info-button-svg" alt="check">
                {{'appointment_create.appointment' | translate }}
            </button>
            <button type="button" class="customer-info-button"
                (click)="type=2">
                <img src="assets/images/svg/stop.svg"
                    class="customer-info-button-svg" alt="check">
                    {{'appointment_create.block' | translate }}
            </button>
        </div>

        <ng-container *ngIf="type == 1">
            <div class="w-full">
                <app-appointment [appointmentDay]=appointmentDay [customerI]="customerI" [text_button_create]="text_button_create"></app-appointment>
            </div>
        </ng-container>
        <ng-container *ngIf="type == 2">
            <div class="w-full">
                <app-block [appointmentDay]=appointmentDay [text_button_create]="text_button_create"></app-block>
            </div>
        </ng-container>
    </div>
</app-modal-data>