import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SettingGeneralService } from '../../shared/services/settings-general.service';
import { Validations } from '../../shared/settings/validation';
import { AppointmentsService } from './appointments.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import { Slug } from 'src/app/shared/settings/slug';
import { TeamService } from 'src/app/shared/services/team.service';

import { v4 as uuidv4 } from 'uuid';import { FindTeamByZipComponent } from 'src/app/dashboard/home/menu/external/findZip/find-team-zip.component';
import { CustomerExternalService } from './customer.service';


@Component({
    selector: 'app-appointments',
    templateUrl: './appointments.component.html',
    styleUrls: ['./appointments.component.scss'],
    animations: [
        trigger(
            'enterAnimation', [
            transition(':enter', [
                style({ transform: 'translateY(100%)', opacity: 0 }),
                animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ transform: 'translateY(100%)', opacity: 1 }),
                animate('500ms', style({ transform: 'translateY(0)', opacity: 0 }))
            ])
        ]
        )
    ],
})

export class AppointmentsComponent implements OnInit, OnDestroy {

    customer;
    isOpenMobile: boolean = true;
    selected: boolean = false;

    dateOptions: FlatpickrOptions;
    dateOptionsDob: FlatpickrOptions;

    disabledDates = ["2021-12-25", "2021-12-31", "2022-01-01"];
    enableDays = [0,1,2,3,4,5,6];
    setHours = null;
    hours = [];

    submitted: boolean = false;
    customerExternalForm: FormGroup;
    validationMaxString = Validations.validationMaxString;

    stepNumber: number = 1;

    private subscription = new Subscription();

    // mobile
    isMobile: boolean = false;
    noShowCalendar: boolean = false;

    noCpError: boolean = false;

    class: string = "grid grid-cols-2 gap-8 p-3";

    user;

    appointments = [];

    allLoading = false;

    srcImage = '/assets/images/flor-circle.png';
    
    param = { name: '', user: '', date: '', hour: '' };
    team_id: number;
    prefix: string;
    settingsValue;
    locale: string;
    localeCalendar: string;
    slug: string;

    hashSchedule: string;

    userData;

    zipCode: string;
    zipcodesConfig: any;
    countryName;

    constructor(
        private builder: FormBuilder,
        private appointmentsService: AppointmentsService,
        public settingGeneralService: SettingGeneralService,
        private deviceService: DeviceDetectorService,
        private modalService: ModalService,
        private route: ActivatedRoute,
        private gtmService: GoogleTagManagerService,
        private router: Router,
        private teamService: TeamService,
        private customerExternalService: CustomerExternalService,
    ) {
        
        if(true === environment.production) {
            this.router.events.forEach(item => {
                if (item instanceof NavigationEnd) {
                    const gtmTag = {
                        event: 'page',
                        pageName: item.url
                    };
    
                    this.gtmService.pushTag(gtmTag);
                }
            });
        }

        const slug = this.slug = this.locale = Slug.normalize(
            (this.router.url).split('/')[2]
        );
        
        this.localeCalendar = this.locale.includes('-') 
            ? 
            this.locale.split('-')[0] : 
            this.locale;

        this.subscription.add(this.teamService.getConfigTeam(slug).subscribe(
            (response) => {
                
                const settings = this.settingsValue = response.data;
                
                this.zipcodesConfig = {
                    'zipcodes': settings.prohibited_zipcodes,
                    'length': settings.zipcode_max_length,
                    'acceptAlpha': settings.zipcode_accept_alpha
                };

                this.settingGeneralService.createDataLayer();
                 
                this.settingGeneralService.generateSettings(settings);

                this.countryName = response.data.name;
                this.settingGeneralService.countryDataLayer(this.countryName);

                this.team_id = settings.team_id;
                this.prefix = settings.prefix;

                this.user = this.route.snapshot.paramMap.get('user');

                if(this.user) {
                    this.appointmentsService.listConfigByUserName(this.user).subscribe(
                        (response) => {this.enableDays = response.days; this.setDay();}
                    );
                    
                } else {

                    if(this.route.snapshot.queryParamMap.get('cp')){
                        console.log('asdf');
                        this.appointmentsService.setZipCode(this.route.snapshot.queryParamMap.get('cp'));

                        this.teamService.getTeamIdByZipcodeAndFatherId(this.team_id, this.route.snapshot.queryParamMap.get('cp')).subscribe(
                            (response) => {
                                this.team_id = response.team_id;
                                
                                this.setDay();
                            },
                            (error) => this.setDay()
                        )
                    } else {
                        if(settings.has_children) {
                            this.allLoading = true;
                            this.showModalZipcode(this.zipcodesConfig);
                            
                            this.appointmentsService.getZipCode$.subscribe(
                                (response) => {
                                    this.allLoading = false;
                                    this.zipCode = response;
                                    this.teamService.getTeamIdByZipcodeAndFatherId(this.team_id, this.zipCode).subscribe(
                                        (response) => {
                                            this.team_id = response.team_id;
                                            
                                            this.setDay();
                                        },
                                        (error) => this.setDay()
                                    )
                                }
                            );
                        } else {
                            this.setDay();
                        }
                    }

                    
                }                
            },
            () => {
                window.open('/appointments/es', "_self");
            }
        ));
        
        this.isMobile = this.deviceService.isMobile();
        
        this.settingGeneralService.changeCountry$.subscribe(
            () => {
                this.settingsValue = this.settingGeneralService.getSettings();
            
                this.dateOptions = {
                    wrap: false,
                    inline: true,
                    locale: this.settingsValue.flatpickr,
                    dateFormat: this.settingsValue.formatFlatpickr,
                    minDate: moment().format(this.settingsValue.formatMoment),
                    maxDate: moment().add(2, 'week').format(this.settingsValue.formatMoment),
                    disableMobile: true,
                    weekStartsOn: this.settingsValue.weekStartsOn
                };
            }
        );
        
        
    }

    private setDay()
    {
        this.changeDay(moment().format('DD-MM-YYYY'), true);
                
        this.dateOptionsCalendar();

        this.allLoading = true;
    }

    get f() { return this.customerExternalForm.controls; }
    
    ngOnInit(): void {
        const day = moment().format('Y-M-D');
        this.customerExternalForm = this.builder.group({
            date: [{ 0:  day}, [Validators.required]],
            hour: [null, [Validators.required]],
        });
    }

    private dateOptionsCalendar() {
        this.dateOptions.disable = [
            (date) => {
                return this.configDates(date);
            }
        ];
    }

    configDates(date) {
        const day = date.getDay();

        const dateNumber = day === 0 ? 6 : (day -1);
    
        if(!this.enableDays.includes(dateNumber))
            return true;
        if (this.disabledDates.includes(moment(date).format('YYYY-MM-DD')))
            return true;
    }

    changeDay($event, first:boolean = false) {
        const selectedDay = moment($event,'DD-MM-YYYY').format('YYYY-MM-DD');

        if(this.user && this.user !== 'undefined'){
            this.hashSchedule = this.route.snapshot.paramMap.get('hash');

            this.subscription.add(this.appointmentsService.getBlockedHoursUser(this.user, this.team_id, selectedDay).subscribe(
                (response) => {
                    this.hours = response.hours;
                    if(first) {
                        const userData = response.userData;
                        this.srcImage = userData.image;
                        this.param.name = userData.name;
                    }
                    
                    this.filterHours($event);
                },
            ));
        } else {
            this.subscription.add(this.appointmentsService.getBlockedHours(this.team_id, selectedDay).subscribe(
                (response) => {
                    this.hours = response;
                    this.filterHours($event);
                },
            ));
        }

        
        if(this.isMobile && !first) {
            this.noShowCalendar = true;
        }    
            
        this.getHours();
    }

    filterHours(day) {
        const current = moment();
        
        if(day === current.format(this.settingsValue.formatMoment)) {
            const now =  current.add(30, 'minutes').format('HH:mm:ss')
            this.hours = this.hours.filter(hour => {
                return hour > now;
            } )
        }
    }

    getHours() {
        this.customerExternalForm.controls['hour'].setValue(null);
        setTimeout(() => {
            this.setHours = this.hours.map(hour => hour.slice(0, hour.lastIndexOf(':')));
        }, 800);
    }

    setHour(hour) {
        this.settingGeneralService.insertDataLayer('Select Slot');
        const splitHour = hour.split(':');
        this.customerExternalForm.controls['hour'].setValue(
            splitHour[0]+':'+splitHour[1]
        );
       
        if(this.hashSchedule) {
            this.reScheduleAppointment();
        } else {
            this.stepNumber = 2;
        }
        
    }

    reScheduleAppointment() {
        this.setData();
        let data = {
            'date': moment(this.f.date.value[0]).format('YYYY-MM-DD'),
            'hour': this.f.hour.value
        }

        this.subscription.add(this.appointmentsService.reScheduleAppointment(this.hashSchedule, data).subscribe(
            () => {
                this.stepNumber = 3;
                this.allLoading = true;
            }
        ))
    }

    comeBack() {
        this.submitted = false;
        this.customerExternalForm.reset();
        this.f.date.setValue({ 0: moment().format('Y-M-D') });
        this.noShowCalendar = false;
        this.stepNumber = 1;

        this.changeDay(moment().format('DD-MM-YYYY'), true);

        this.settingGeneralService.insertDataLayer('Edit Slot');
    }

    private setData()
    {
        moment.locale(this.locale);
        this.param.date = moment(this.f.date.value[0]).format('dddd DD/MM');
        this.param.hour  = this.f.hour.value
    }

    onSubmit($event) {
        this.submitted = true;

        if (this.customerExternalForm.invalid) {
            return;
        }
        
        this.allLoading = false;
        
        $event.date = moment(this.f.date.value[0]).format('YYYY-MM-DD');
        $event.hour = this.f.hour.value;
        $event.team_id = this.team_id;
        $event.prefix = this.prefix;
        $event.uuid = uuidv4();

        this.setData();
        if(!moment(this.f.date.value[0]).isValid()) {
            this.comeBack();
        }

        this.subscription.add(this.appointmentsService.saveCustomerAppointment($event, this.user).subscribe(
            (response) => {
                this.appointmentsService.getAppointmentByUuid($event.uuid).subscribe(
                    (response) => {
                        this.param.name = response.userName;
                        this.param.user = response.customerName;
                        this.srcImage = response.userImage;

                        this.stepNumber = 3;
                        this.allLoading = true;

                        this.cookies($event, response.customerId);
                    }
                );
            },
            () => {
                this.allLoading = true;
                this.comeBack();
            }
            
        ))
    }

    private cookies(customer, customerId)
    {
        const cookies = document.cookie.split(';');
        const googleCookies = cookies.filter(cookie => cookie.startsWith(' _ga=') || cookie.startsWith(' _gid='));
        
        if(googleCookies.length > 0) {
            this.subscription.add(
                this.customerExternalService.saveGoogleCookiesByUuid(customerId, googleCookies).subscribe(
                    (response) => this.settingGeneralService.submitFormDatalayer(this.countryName, customer, customerId)
                )
            );
        } else {
            this.settingGeneralService.submitFormDatalayer(this.countryName, customer, customerId)
        }
    }

    showModalZipcode(zipCodesConfig) {
        let inputs = this.settingGeneralService.getLangText('external_modal');
        inputs.id_team = this.team_id;
        inputs.zipCodesConfig = zipCodesConfig;

        this.modalService.init(FindTeamByZipComponent, inputs, { closeModal: this.closeModal.bind(this), buttonAction: this.closeModal.bind(this) });
    }
    
    closeModal() {
        this.modalService.destroy();
    }

    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}