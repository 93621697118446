import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CustomerEditNutritionalPlanService {

    constructor(private http: HttpClient) { }

    private crashSubject = new Subject<any>();
    public crashState = this.crashSubject.asObservable();

    getNutritionalPlanDataDiets(is_shock:number, page: number = 1, per_page: number = 15, query?: string)
    {
        let url = `${environment.apiUrl}/admin/diets?page=${page}&per_page=${per_page}&is_shock=${is_shock}&order_by=number_of_assignments`
        if(query) {
            url+=`&name=${query}`;
        }

        return this.http.get(url)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    getNutritionalPlanData(type:string, page: number = 1, per_page: number = 15, query?: string)
    {
        let url = `${environment.apiUrl}/admin/${type}?page=${page}&per_page=${per_page}&order_by=number_of_assignments`
        if(query) {
            url+=`&name=${query}`;
        }

        return this.http.get(url).pipe(map((response: any) => {
                return response.data;
            }));
    }

    getNutritionalPlan(customer_id, appointment_id) {
        return this.http.get(`${environment.apiUrl}/nutritional-plan/${customer_id}/${appointment_id}`)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    saveNutritionalPlan(customer_id, appointment_id, body) {
        return this.http.post(`${environment.apiUrl}/nutritional-plan/${customer_id}/${appointment_id}`, body)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    changeCrashState(days) {
        this.crashSubject.next({ days: days });
    }
}